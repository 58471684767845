var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "menuLayout", staticClass: "menu-layout" }, [
    _c(
      "div",
      { staticClass: "left-menu-panel" },
      [
        _vm.getshowSet
          ? _c("div", { staticClass: "go-back-menu-btn" }, [
              _c("div", { on: { click: _vm.goback } }, [_vm._v("返回")]),
              _c("div", [_vm._v("xxxxx")]),
            ])
          : _vm._e(),
        _vm._t("leftTop"),
        _c("scMenu", {
          ref: "scmenu",
          attrs: {
            indent: "0.1198rem",
            "data-list": _vm.menuDataList,
            "selected-key": _vm.selectedKeyInner,
          },
          on: { change: _vm.menuSelectChange },
          scopedSlots: _vm._u([
            {
              key: "menuItem",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticClass: "left-layout-menu-item" }, [
                    data.info[_vm.replaceFields.frontIcon]
                      ? _c("div", { staticClass: "menu-item-icon" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "iconpark-icon",
                              style: {
                                width: "16px",
                                height: "16px",
                                marginRight: "8px",
                              },
                            },
                            [
                              _c("use", {
                                staticClass: "icon-use",
                                attrs: {
                                  href:
                                    "" + data.info[_vm.replaceFields.frontIcon],
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "menu-item-name",
                        attrs: { title: data.info.name },
                      },
                      [_vm._v(_vm._s(data.info.name))]
                    ),
                    _c(
                      "div",
                      { staticClass: "menu-item-action" },
                      [
                        _c(
                          "a-popover",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !!data.info[_vm.replaceFields.actions] &&
                                  data.info[_vm.replaceFields.actions].length >
                                    0,
                                expression:
                                  "\n                !!data.info[replaceFields.actions] && data.info[replaceFields.actions].length > 0\n              ",
                              },
                            ],
                            ref: "menupop" + data.info[_vm.replaceFields.key],
                            attrs: {
                              "overlay-class-name": "menu-popover",
                              placement: "bottomRight",
                              trigger: "hover",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (function (e) {
                                  return e.preventDefault()
                                })($event)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("div", { staticClass: "popover-action-c" }, [
                                data.info[_vm.replaceFields.actions].includes(
                                  "edit"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "action-c-btn action-c-edit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.menuItemEdit(
                                              data.info,
                                              data.path
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                data.info[_vm.replaceFields.actions].includes(
                                  "del"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "action-c-btn action-c-del",
                                        on: {
                                          click: function ($event) {
                                            return _vm.menuItemDel(
                                              data.info,
                                              data.path
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("a-icon", { attrs: { type: "ellipsis" } }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "submenuTitle",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticClass: "left-layout-submenu-item" }, [
                    data.info[_vm.replaceFields.frontIcon]
                      ? _c("div", { staticClass: "menu-submenu-icon" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "iconpark-icon",
                              style: {
                                width: "16px",
                                height: "16px",
                                marginRight: "8px",
                              },
                            },
                            [
                              _c("use", {
                                staticClass: "icon-use",
                                attrs: {
                                  href:
                                    "" + data.info[_vm.replaceFields.frontIcon],
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "menu-submenu-name menu-submenu-title" },
                      [_vm._v(_vm._s(data.info.name))]
                    ),
                    _c(
                      "div",
                      { staticClass: "menu-submenu-action" },
                      [
                        _c(
                          "a-popover",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !!data.info[_vm.replaceFields.actions] &&
                                  data.info[_vm.replaceFields.actions].length >
                                    0,
                                expression:
                                  "\n                !!data.info[replaceFields.actions] && data.info[replaceFields.actions].length > 0\n              ",
                              },
                            ],
                            ref: "menupop" + data.info[_vm.replaceFields.key],
                            attrs: {
                              placement: "bottomRight",
                              "overlay-class-name": "menu-popover",
                              trigger: "hover",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (function (e) {
                                  return e.preventDefault()
                                })($event)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("div", { staticClass: "popover-action-c" }, [
                                data.info[_vm.replaceFields.actions].includes(
                                  "edit"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "action-c-btn action-c-edit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.menuItemEdit(
                                              data.info,
                                              data.path
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                data.info[_vm.replaceFields.actions].includes(
                                  "del"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "action-c-btn action-c-del",
                                        on: {
                                          click: function ($event) {
                                            return _vm.menuItemDel(
                                              data.info,
                                              data.path
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("a-icon", { attrs: { type: "ellipsis" } }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
          ]),
        }),
        _vm._t("leftBottom"),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "right-menu-panel",
        style: {
          width:
            "calc(100% - " +
            (/^[0-9]+$/.test(_vm.leftPanelWidth)
              ? _vm.leftPanelWidth + "px"
              : _vm.leftPanelWidth) +
            " - 2px)",
          minHeight: _vm.layoutHeight + "px",
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }